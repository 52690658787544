/* Carousel - Start
================================================== */

// Carousel Nav - Start
.carousel_nav {
	gap: 10px;
	display: flex;
	padding: 50px 0;
	align-items: center;
	justify-content: center;
	[class*="carousel_nav_btn_"] {
		width: 50px;
		height: 50px;
		flex: 0 0 auto;
		cursor: pointer;
		position: relative;
		align-items: center;
		border-radius: 100%;
		display: inline-flex;
		justify-content: center;
		color: var(--bs-heading-color);
		transition: var(--bs-transition);
		border: 1px solid rgba(var(--bs-light-rgb), 0.1);
		&:hover {
			color: var(--bs-dark);
			background-color: var(--bs-heading-color);
		}
	}
}
// Carousel Nav - End

// Carousel Pagination - Start
.swiper-pagination-bullets {
	padding: 50px 0;
	>* {
		margin: 0;
		width: 10px;
		height: 10px;
		transition: var(--bs-transition);
		background-color: var(--bs-white);
		&:hover {
			opacity: 1;
		}
	}
}
// Carousel Pagination - End

/* Carousel - End
================================================== */