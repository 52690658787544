/* Rating - Start
================================================== */
.rating_star {
  gap: 6px;
  >li {
    width: 15px;
    flex: 0 0 auto;
    img {
      width: 100%;
    }
  }
}
/* Rating - End
================================================== */