/* Hero Section - Start
================================================== */
.hero_section {
	z-index: 1;
	display: flex;
	position: relative;
	align-items: center;
	padding: 170px 0 140px;
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
	&:before {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		z-index: -1;
		position: absolute;
		background: linear-gradient(0deg, var(--bs-dark), rgba(var(--bs-dark-rgb), 0.5));
	}
	.hero_description {
		margin: auto;
		max-width: 850px;
	}

	.shape_light_2 {
		top: -190px;
		right: -200px;
		width: 2000px;
		animation: fadeInOut 3s infinite;
	}
}

.hero_title {
	line-height: 1;
	font-size: 140px;
	margin-bottom: 10px;
}
.hero_description {
	font-size: 20px;
}
/* Hero Section - End
================================================== */