/* Table Block - Start
================================================== */
table {
	--bs-bg-opacity: 0.67;
	--bs-warning-rgb: 255, 192, 120;
	--bs-danger-rgb: 255, 130, 141;
	width: 100%;
	border-collapse: unset;
	padding: 23px 48px 33px;
	border-radius: var(--bs-border-radius);
	background-color: rgba(var(--bs-light-rgb), 0.1);
	border: 1px solid rgba(var(--bs-border-color), 0.1);
	&:not(:last-child) {
		margin-bottom: 24px;
	}
	thead {
		font-size: 20px;
		margin-bottom: 10px;
		box-shadow: 0 1px 0 0 rgba(var(--bs-border-color), 0.1);
	}
	th {
		padding: 20px 0;
	}
	tbody {
		font-size: 18px;
		&:before {
			content: '';
			width: 100%;
			height: 20px;
			display: block;
		}
	}
	td {
		padding: 10px 0px;
	}
}
/* Table Block - End
================================================== */