/* Site Header - Start
================================================== */

// Header Elements - Start
.site_logo {
	.site_link {
		width: 220px;
		flex: 0 0 auto;
	}
}

.site_header {
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	position: fixed;
	.nav_wrapper {
		padding: 25px 0;
		transition: padding .3s;
	}
	&.sticky {
		.nav_wrapper {
			padding: 10px 0;
			backdrop-filter: saturate(180%) blur(20px);
			background-color: rgba(var(--bs-dark-rgb), 0.1);
		}
	}

	.btn {
		padding: 15px 32px 14px;
	}

	.btns_group {
		>li {
			&:first-child {
				display: none;
			}
		}
	}
}

// Main Menu - Start
.main_menu_list {
	gap: 40px;
	>li {
		>.nav-link {
			gap: 4px;
			z-index: 1;
			display: flex;
			line-height: 1;
			font-size: 16px;
			flex-wrap: wrap;
			overflow: hidden;
			font-weight: 500;
			line-height: 18px;
			position: relative;
			align-items: center;
			color: var(--bs-heading-color);
			font-family: var(--bs-body-font-family);
			>.nav_link_label {
				padding: 1px 0;
				position: relative;
				display: inline-block;
				transition: transform 0.3s;
				&:before {
					left: 0;
					top: 100%;
					text-align: center;
					position: absolute;
					content: attr(data-text);
				}
			}
			>.nav_link_icon {
				font-size: 14px;
				margin: 2px 0 0;
				color: var(--bs-heading-color);
			}
		}
		&.active {
			>.nav-link {
				color: var(--bs-primary);
				>.nav_link_icon {
					color: var(--bs-primary);
				}
			}
		}
		&:hover {
			>.nav-link {
				color: var(--bs-primary);
				.nav_link_label {
					transform: translateY(-96%);
				}
				>.nav_link_icon {
					color: var(--bs-primary);
					transform: rotateX(-180deg);
				}
			}
		}
	}

	.nav_link_icon {
		transition: var(--bs-transition);
	}
}

@media screen and (min-width: 992px) {
	.main_menu_list {
		.dropdown-menu {
			opacity: 0;
			display: block;
			min-width: 210px;
			transition: 200ms;
			visibility: hidden;
			transform-origin: top;
			transform: perspective(300px) rotateX(-8deg);
		}
		.dropdown {
			&:hover {
				>.dropdown-menu {
					opacity: 1;
					visibility: visible;
					transform: perspective(300px) rotateX(0deg);
				}
			}
		}
	}

	.main_menu_list {
		>.dropdown {
			>.dropdown-menu {
				margin-top: 41px;
				&:before {
					left: 0;
					right: 0;
					top: -25px;
					content: '';
					height: 25px;
					display: block;
					position: absolute;
				}
			}
		}
	}
	.site_header.sticky {
		.main_menu_list {
			>.dropdown {
				>.dropdown-menu {
					margin-top: 46px;
					&:before {
						top: -30px;
						height: 30px;
					}
				}
			}
		}
	}
}
// Main Menu - End

// Mobile Menu Button - Start
.mobile_menu_btn {
	width: 50px;
	height: 50px;
	flex: 0 0 auto;
	font-size: 20px;
	border-radius: 100%;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	color: var(--bs-heading-color);
	border: 1px solid rgba(var(--bs-border-color), 0.2);
	&:hover {
		color: var(--bs-dark);
		border-color: var(--bs-white);
		background-color: var(--bs-white);
	}
}
// Mobile Menu Button - End

// Nav Responsive - Start
@media screen and (max-width: 1024px) {
	.main_menu_list {
		gap: 30px;
	}
}

@media screen and (max-width: 991px) {
	.site_header .btns_group > li:first-child {
		display: inline-block;
	}
	.site_header .nav_wrapper {
		padding: 15px 0;
	}
	.site_header .main_menu {
		left: 0;
		right: 0;
		top: 80px;
		z-index: 999;
		position: fixed;
		padding: 0 15px;
	}
	.site_header.sticky .main_menu {
		top: 70px;
	}
	.main_menu_list {
		gap: 1px;
		margin: auto;
		max-width: 700px;
		padding: 20px 30px;
		border-radius: 10px;
		background-color: var(--bs-white);
		box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.6);
	}
	.main_menu_list > li {
		width: 100%;
		display: block;
		padding: 15px 0;
		&:not(:last-child) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
	.main_menu_list > li > .nav-link {
		width: 100%;
		display: block;
		font-size: 18px;
		line-height: 20px;
		color: var(--bs-dark);
	}
	.main_menu_list > li > .nav-link > .nav_link_icon {
		color: var(--bs-dark);
	}
	.main_menu_list .dropdown-menu {
		margin-top: 8px;
		position: static;
		box-shadow: none;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
}

@media screen and (max-width: 680px) {
	.site_header .btns_group > li:last-child {
		display: none;
	}
}
// Nav Responsive - End

/* Site Header - End
================================================== */