/* Call To Action Section - Start
================================================== */
.cta_section {
	.heading_block {
		.heading_description {
			margin: 0 auto;
			max-width: 350px;
		}
	}
	.brand_logo {
		max-width: 230px;
		display: inline-block;
	}
}
/* Call To Action Section - End
================================================== */