/* Timeline Block - Start
================================================== */
.process_section {
	.boxed_wrapper {
		&.bg-white {
			--bs-white-rgb: 249, 249, 249;
		}
		.brand_logo {
			max-width: 200px;
		}
	}
}

.process_block {
	gap: 60px;
	padding-left: 40px;
	border-left: 1px solid rgba(var(--bs-border-color), 1);
	>li {
		position: relative;
		&:before {
			top: 20px;
			left: -45px;
			width: 9px;
			height: 9px;
			content: "";
			position: absolute;
			border-radius: 100%;
			transition: var(--bs-transition);
			background-color: rgba(var(--bs-border-color), 1);
		}
		&:has([aria-expanded="true"]) {
			&:before {
				background-color: var(--bs-primary);
			}
		}
		@media screen and (min-width: 998px){
			&:nth-child(2){
				padding-left: 10%;
			}
			&:nth-child(3){
				padding-left: 24%;
			}
			&:nth-child(4){
				padding-left: 38%;
			}
			&:nth-child(5){
				padding-left: 52%;
			}
			&:nth-child(6){
				padding-left: 66%;
			}
			&:nth-child(7){
				padding-left: 80%;
			}
		}
		
	}

	.collapse_head {
		.badge {
			gap: 30px;
			line-height: 1;
			font-size: 22px;
			font-weight: 700;
			position: relative;
			border-radius: 50px;
			align-items: center;
			margin-bottom: 16px;
			display: inline-flex;
			padding: 14px 24px 12px;
			color: var(--bs-body-color);
			transition: var(--bs-transition);
			font-family: var(--bs-heading-font-family);
			border: 1px solid rgba(var(--bs-border-color), 1);
			&:after {
				content: '\f055';
				font-weight: 400;
				display: inline-block;
				transform-origin: center center;
				font-family: 'Font Awesome 5 Pro';
			}
			&[aria-expanded="true"] {
				color: var(--bs-white);
				border-color: var(--bs-primary);
				background-color: var(--bs-primary);
				&:after {
					content: '\f056';
				}
			}
			&:not([aria-expanded="true"]) {
				&:hover {
					color: var(--bs-primary);
				}
			}
		}
		.heading_title {
			&:hover {
				color: var(--bs-primary);
			}
			transition: var(--bs-transition);
		}
	}

	.heading_title {
		font-size: 26px;
		margin-bottom: 0;
	}
	.card {
		border-radius: 0;
		padding: 20px 0 0;
		background-color: transparent;
	}
}
/* Timeline Block - End
================================================== */
