/* Career Page - Start
================================================== */
.career_accordion {
	.accordion-header {
		gap: 20px;
		font-size: 60px;
	}
	.accordion-header:before {
		margin: 0;
		width: 60px;
		height: 60px;
	}
}
/* Career Page - End
================================================== */